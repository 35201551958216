class ZIDXAccountHelp implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountHelpContainer";
    }
    getMatchURL(){
        return "/account/help";
    }
    render(){
        // console.log("help");
    }
}